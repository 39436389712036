import React, { Suspense, lazy } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import QuickEnquiry from "./Components/QuickEnquiry";
import CallingOptions from "./Components/SmallComponent/CallingOptions";
import Whatsapp from "./Components/SmallComponent/Whatsapp";
import { Box, keyframes, Flex } from "@chakra-ui/react";
import "./App.css";
const AllRoutes = lazy(() => import("./Components/AllRoutes"));
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
function App() {
  const renderLoader = () => (
    <Flex minH={"100vh"} align="center" justify="center">
      <Box
        border="16px solid #f3f3f3"
        borderRadius="50%"
        borderTop="16px solid var(--action-button-color)"
        width="120px"
        height="120px"
        animation={`${spin} 0.5s linear infinite`}
        margin="auto"
      ></Box>
    </Flex>
  );
  return (
    <Box
   //  backgroundImage={'url("/Images/bg.png")'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'}
     >
      <Navbar />
      <Suspense fallback={renderLoader()}>
        <AllRoutes />
      </Suspense>
      <Footer />
      <QuickEnquiry />
      <CallingOptions />
      <Whatsapp />
    </Box>
  );
}

export default App;
