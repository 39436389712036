import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";

function CallingOptions(props) {
  const [isHovered, setIsHovered] = useState(false);
 console.log(isHovered);
  return (
    <div>
      <Box
        position={"fixed"}
        cursor={"pointer"}
        right={"0"}
        bottom={"30%"}
        zIndex={"1000"}
      >
      
          {" "}
          <Flex
            alignItems={"center"}
           bg={'green'}
            color="white"
            p={"8px 5px"}onMouseEnter={(prev)=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}
            borderRadius={"10px 0px 0px 10px"}
            fontWeight={"bold"}
         
          >
            <BiSolidPhoneCall size={"28px"}  />{" "}
         {isHovered?    <a href="tel:+919164213141">  <Text pl={"10px"}> +91 9164213141 </Text> </a>:""}
          </Flex>
 
      </Box>
    </div>
  );
}

export default CallingOptions;
