import { Box } from "@chakra-ui/react";
import React from "react";
import { ImWhatsapp } from "react-icons/im";

function Whatsapp(props) {
  const handleClick = () => {
    const formattedNumber = "+ 91 64 21 31 41".replace(/\D/g, ""); // Remove non-digit characters
    const whatsappLink = `https://wa.me/${formattedNumber}`;

    window.open(whatsappLink, "_blank");
  };
  return (
    <div>
      <Box
        position={"fixed"}
        cursor={"pointer"}
        left={"0"}
        bottom={"10%"}
        p={"10px"}
        zIndex={"1000"}
        onClick={handleClick}
      >
        {" "}
        <ImWhatsapp color="green" size={"40px"} />{" "}
      </Box>
    </div>
  );
}

export default Whatsapp;
