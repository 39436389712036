import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Image,
  DrawerHeader,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";

import "./Navbar.css";
const Navbar = () => {
  const [navBackground, setNavBackground] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleScroll = () => {
    const isTop = window.scrollY > 100;
    if (isTop !== navBackground) {
      setNavBackground(isTop);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navBackground]);

  return (
    <>
      <Box
        px={[5, 5, 10]}
        py={1}
        fontWeight={"600"}
        fontSize={["13px", "16px"]}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundColor={
          navBackground ? "var(--main-color)" : "rgba(211, 211, 211,0.5)"
        }
        // bg={"rgba(255, 255, 255, 0.1)"}
        //   backdropFilter={'blur(5px)'}
        boxShadow={"0 4px 6px rgba(0, 0, 0, 0.1)"}
        width="100%"
        position={"fixed"}
        top={0}
        zIndex={1000}
        color="black"
        fontFamily={"itim"}
        //   h='80px'

        transition="background-color 0.3s"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          color={"black"}
        >
          {" "}
          <NavLink to="/">
            <Box fontWeight="bold" fontSize="lg">
              <Image
                src="/Images/logo.png"
                width={"90px"}
                //  style={{ transition: "width 2s" }}
                height={"90px"}
                borderRadius={"10px"}
                _hover={{
                  transform: "scale(1.1)",
                  transition: "transform 0.5s",
                }}
                //  _hover={{width:"90px",height:"80px"}}
              />
            </Box>
          </NavLink>
          <Flex
            w={"50%"}
            justifyContent={"space-around"}
            display={["none", "none", "none", "flex"]}
          >
            <NavLink to={"/homestay-sakleshpur"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                }}
              >
                {" "}
                STAY
              </Text>
            </NavLink>
            <NavLink to={"/things-to-do-in-sakleshpur"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                }}
              >
                {" "}
                SIGHTSEEING
              </Text>
            </NavLink>
            <NavLink to={"/about-us"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                }}
              >
                {" "}
                ABOUT US
              </Text>
            </NavLink>
            <NavLink to={"/gallery"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                }}
              >
                {" "}
                GALLERY
              </Text>
            </NavLink>
            <NavLink to={"/riverside-resort-sakleshpur"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                }}
              >
                {" "}
                BLOGS
              </Text>
            </NavLink>
            <NavLink to={"/best-resort-in-sakleshpur"}>
              {" "}
              <Text
                transition="transform 0.2s"
                _hover={{
                  transform: "scale(1.4)",
                  transition: "transform 0.5s",
                  color: "green",
                }}
              >
                {" "}
                CONTACT US
              </Text>
            </NavLink>
          </Flex>
          <Box
            onClick={isOpen ? onClose : onOpen}
            display={["block", "block","block", "none"]}
          >
            <IconButton
              // display={{ base: "block", md: "none" }}
              icon={
                isOpen ? (
                  "close"
                ) : (
                  <HamburgerIcon color="black" fontSize={"50px"} />
                )
              }
              variant="ghost"
              color="white"
              aria-label="Toggle navigation"
            />

            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
              <DrawerOverlay />
              <DrawerContent
                h={"100vh"}
                color="white"
                cursor={"pointer"}
                //  _hover={{ color: "#F8B654" }}
              >
                <DrawerCloseButton size={"35px"} mt={"10px"} color={"white"} />
                <DrawerHeader
                  bg={"var(--bg-color)"}
                  textAlign={"center"}
                  color={"white"}
                  fontFamily={"itim"}
                >
                  {" "}
                  <NavLink to={"/"}> Home</NavLink>
                </DrawerHeader>
                <DrawerBody>
                  <Box
                    mb={"50px"}
                    textAlign={"center"}
                    fontWeight={500}
                    fontSize={"25px"}
                    fontFamily={"itim"}
                  >
                    <NavLink to={"/homestay-sakleshpur"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Tariff
                      </Box>{" "}
                    </NavLink>

                    <NavLink to={"/things-to-do-in-sakleshpur"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Sightseeing
                      </Box>{" "}
                    </NavLink>

                    {/* <NavLink to={"/activities"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Activities
                      </Box>{" "}
                    </NavLink> */}

                    <NavLink to={"/gallery"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Gallery
                      </Box>{" "}
                    </NavLink>
                    <NavLink to={"/about-us"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        About us
                      </Box>{" "}
                    </NavLink>
                    <NavLink to={"/best-resort-in-sakleshpur"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Contact us
                      </Box>{" "}
                    </NavLink>
                    <NavLink to={"/riverside-resort-sakleshpur"}>
                      {" "}
                      <Box
                        p={"10px"}
                        bg={"var(--bg-color)"}
                        m={"5px"}
                        mb={"20px"}
                        borderRadius={"5px"}
                      >
                        Blogs
                      </Box>{" "}
                    </NavLink>
                  </Box>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Navbar;
