import { Box, Divider, Flex, Img, Text } from "@chakra-ui/react";
import React from "react";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import Explore_places from "./Explore_places";
function Footer() {
  const handleClick = () => {
    const formattedNumber = "+916364666662".replace(/\D/g, ""); // Remove non-digit characters
    const whatsappLink = `https://wa.me/${formattedNumber}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Box>
      {" "}
      <Explore_places />
      <Box
        bg="var(--main-color)"
        color={"black"}
        fontFamily={"inika"}
        letterSpacing={"2px"}
        pb={"20px"}
        
      >
        <Box
          display={["block","block","block", "flex"]}
          justifyContent={"space-between"}
          w={["95%", "100%"]}
          m="auto"
          alignItems={"center"}
          pt={"50px"} 
        >
          <Box  w={["100%","50%","50%", "15%"]} m='auto'>
            <Img src="/Images/logo.png" w={"100%"} />
          </Box>
          <Box w={["95%", "85%","85%", "50%"]} m={'auto'}>
            {/* <Text fontSize={24} fontFamily={"Merienda"}>
            Perfect For
          </Text> */}

            <Text wordBreak={'break-word'} pt={"10px"} fontSize={'22px'} fontFamily={'inika'} fontWeight={400}>
              Nature Trails Resort is a renowned riverside resort located in the picturesque locale of Sakleshpur, Karnataka, India. With its serene environment and proximity to the Western Ghats, the resort offers a unique combination of relaxation and adventure to its guests.
            </Text>
          </Box>

          <Box w={["90%","90%","45%", "30%"]} m='auto'>
            <Text color="black" fontFamily={"Merienda"}>
              Connect with us :
            </Text>
            <Flex p={["10px 10px"]} alignItems={"center"}>
              {" "}
              <BiSolidPhoneCall size={"25px"} />
              <Text pl={"15px"} fontFamily={"Merienda"}>
                {" "}
                +919164213141{" "}
              </Text>{" "}
            </Flex>
            <Flex p={["10px 10px"]} alignItems={"center"}>
              {" "}
              <MdEmail size={"25px"} />
              <Text pl={"15px"}> bookings@letshittheroad.in </Text>{" "}
            </Flex>
            <Flex p={"20px"} justifyContent={"space-around"} w={'50%'} m={'auto'}>
              <Box
                _hover={{
                  color: "#0962F6",
                  transform: "scale(1.5)",
                  transition: "transform 0.5s",
                }}
              >
                <a
                  href="https://www.facebook.com/NatureTrailsSakleshpur"
                  target="_blank"
                >
                  <BsFacebook size={"25px"} />
                </a>
              </Box>
              <Box
                _hover={{
                  color: "#F403D3",
                  transform: "scale(1.5)",
                  transition: "transform 0.5s",
                }}
              >
                <a
                  href="https://www.instagram.com/naturetrailssakleshpur/"
                  target="_blank"
                >
                  <AiFillInstagram size={"30px"} />
                </a>
              </Box>
              <Box
                _hover={{
                  color: "#F60100",
                  transform: "scale(1.5)",
                  transition: "transform 0.5s",
                }}
              >
                <a
                  href="https://www.youtube.com/@NatureTrailsSakleshpur"
                  target="_blank"
                >
                  <AiFillYoutube size={"30px"} />
                </a>
              </Box>
              {/* <Box
              _hover={{
                color: "green",
                transform: "scale(1.5)",
                transition: "transform 0.5s",
              }} onClick={handleClick}
            >
              {" "}
              <a href="https://www.whatsapp.com" target="_blank">
                <RiWhatsappFill size={"30px"} />
              </a>
            </Box> */}
            </Flex>
          </Box>
        </Box>

        <Box
          display={["block","block", "flex"]}
          justifyContent={"space-between"}
          w={["90%", "85%"]}
          m="auto"
          alignItems={'end'}
         // flexDir={["column-reverse", "row"]}
         flexDir={'row-reverse'}
         
         // pt={"10px"}
        >
         
          <Box display={'flex'} w={['100%',"40%","40%",'30%']} alignItems={'end'}>
            <Box  w={"10%"}> 
            <a href="https://www.letshittheroad.in">
              {" "}
              <Img 
                src="https://www.letshittheroad.in/Images/logo.webp"
                w={"90%"} 
              />
            </a></Box>
            <Text w={['100%',"90%","90%","90%"]} whiteSpace={'nowrap'}> Marketed By Let'shittheroad</Text>
          </Box> <Box>

            <Text fontSize={"10px"} pt={["5px",0]}>
              © {new Date().getFullYear()} let's hit the road All rights
              reserved{" "}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
