import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { CheckIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";

function QuickEnquiry() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const form = useRef();
  const [loading, setloading] = useState(false);

  const [userIpAddress, setUserip] = useState();
  const getUserIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setUserip(data?.ip);
    } catch (error) {
      console.error("Error getting IP address:", error);
      return "Unknown IP";
    }
  };

  useEffect(() => {
    getUserIpAddress();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setloading(true);
    emailjs
      .sendForm(
        "service_906yyj8",
        "template_hm5qw0h",
        form.current,
        "EHsuZqXVEuCN58cZh"
      )
      .then(
        (result) => {
          setloading(false);
          form.current.reset();
          onClose();
          navigate("/thank-you");
        },
        (error) => {
          toast({
            title: "Something went wrong",
            status: "error",
            isClosable: true,
            position: "top",
          });
          setloading(false);
          onClose();
          form.current.reset();
        }
      );
  };
  const validateMobileNumber = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const maxLength = 10;

    // Update the input value in the field
    e.target.value = input.slice(0, maxLength);
  };

  return (
    <div>
      <Box
        position={"fixed"}
        cursor={"pointer"}
        right={"0"}
        bottom={"40%"}
        zIndex={"1000"}
        style={{ writingMode: "vertical-lr" }}
        onClick={onOpen}
      >
        <Text
          pl={"10px"}
          bg={"var(--box-pink-color)"}
          borderRadius={"10px 0px 0px 10px"}
          fontWeight={600} fontFamily={'itim'} fontSize={['18px','20px']}
          p={"20px 5px"} color={'white'}
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
          // _hover={{ transform: "scale(1.4)", transition:"transform 0.5s", color: "green" }}
        >
          {" "}
          Quick Enquiry
        </Text>
      </Box>
      <Drawer
        placement={"right"}
        onClose={onClose}
        isOpen={isOpen}
        size={["md", "xs"]}
        borderRadius={"25px"}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={"var(--bg-color)"}
          //   backgroundImage={'url("/Images/black_scratch_bg.png")'}
          h={"max-content"}
          w={["90%", "40%"]}
          m="auto"
          borderRadius={"10px"}
          mb={"0px"}
          //  border={"2px solid white"}
        >
          <DrawerCloseButton color={"white"} size={"lg"} fontWeight={700} />
          <Box color={"white"}>
            <Box
              p="15px"
              justifyContent={"space-around"}
              // backgroundImage="url('/Images/brownbg.webp')"
              // backgroundSize="cover"
              // backgroundPosition="center"
              // backgroundRepeat="no-repeat"
              //      backgroundImage="linear-gradient(to right, #5a3f37, #2c7744)"
              width="100%"
            >
              <Text
                textAlign="center"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                fontSize="25px"
                fontWeight={"bold"}
              >
                Let's Connect
              </Text>
              {/* <Text textAlign={"center"}>
                {" "}
                Please leave us your contact details and our team will get back
                to you within 24 hours. Your information is safe with us.
              </Text> */}
              <form ref={form} onSubmit={sendEmail}>
                <Box p={"7px"} display="block">
                  <label>Name</label>
                  <Input
                    type="text"
                    color={"black"}
                    bg={"#faf9f5"}
                    name="name"
                    id="name"
                    required
                  />
                </Box>
                <Box p={"10px"}>
                  <label htmlFor="number">Mobile Number</label>
                  <Input
                    type="tel"
                    color={"black"}
                    bg={"white"}
                    pattern="[0-9]{10}"
                    placeholder="Please type 10 digit mobile number"
                    name="number"
                    id="number"
                    onChange={validateMobileNumber}
                    required
                  />
                </Box>
                <Box p={"7px"}>
                  <label htmlFor="groupsize">Group Size</label>
                  <Input
                    name="groupsize"
                    bg={"white"}
                    color="black"
                    id="groupsize"
                    type="number"
                    required
                  />
                </Box>
               

              <Box p={"7px"}>
                <label htmlFor="room_category">Room Category</label>
                <Select
                  id="room_category"
                  name="room_category"
                  bg="white"
                  color={"black"}
                  placeholder="Please select room category"
                  required
                >
                  {" "}
                  <option value="Couple Cottages">Couple Cottages</option>
                  <option value="Dorm Cottage">
                    Dorm Cottage
                  </option>
                </Select>
              </Box>
                <Box p={"7px"}>
                  <label htmlFor="Subject">Subject</label>
                  <Input
                    name="Subject"
                    color={"black"}
                    bg={"white"}
                    id="Subject"
                  />
                </Box>
                <input
                  id="userIpAddress"
                  name="userIpAddress"
                  value={userIpAddress}
                  style={{ visibility: "hidden" }}
                  p={"0px"}
                  m="0"
                  fontSize={"1px"}
                />
                <Button
                  display={"block"}
                  width={"max-content"}
                  m="auto"
                  type="submit"
                  mt={"20px"}
                  color="white"
                  bg={"#FE7069"}
                  fontWeight={700}
                  fontSize={["19px", "20px"]}
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                  isLoading={loading}
                  _hover={{ bg: "#306b29" }}
                >
                  {" "}
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default QuickEnquiry;
