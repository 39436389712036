import React from "react";
import { Box, Img, SimpleGrid } from "@chakra-ui/react";

const Explore_places = () => {
  const places = [
    {
      image: "/Images/explore_ramnagara.jpg",
      link: "https://www.campfirecircle.in",
    },
    {
      image: "/Images/explore_chikmagalur.jpg",
    link: "https://coffeesafari.in", 
    },
    {
      image: "/Images/explore_rangitaranga.jpg",
      link: "https://www.rangitaranga.in",
    },
    {
      image: "/Images/explore_sakleshpur.jpg",
       link: "https://www.tuskervalley.in",
    },
  ];

  return (
    <Box position="relative" mt="50px" textAlign="center">
      <Box
        fontSize={["15px", "25px"]}
        fontWeight={700}
        bg="white"
        p="7px 15px"
        borderRadius="20px"
        border="4px solid var(--main-color)"
        position="absolute"
        top="0%"
        left="50%"
        transform="translate(-50%, -50%)"
        whiteSpace={"nowrap"}
      >
       Properties Hosted by Vivacious Destinations
      </Box>
      <SimpleGrid columns={[2, 4]} border="4px solid var(--main-color)">
        {places?.map((ele, index) => (
          <Box key={index} border="2px solid var(--main-color)">
            <a href={ele.link} target="_blank">
              <Img src={ele.image} alt={ele.image}  objectFit={'cover'}/>
            </a>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Explore_places;
